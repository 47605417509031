/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useLocalStorage } from 'react-use';
import { CheckCircle, DotsThreeOutlineVertical, FolderPlus } from '@phosphor-icons/react';

import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import TransactionListFilter, {
  IFilters,
  defaultTransactionListFilters,
} from '../../../components/Transaction/TransactionList/TransactionListFilter';
import TransactionListAppliedFilter from '../../../components/Transaction/TransactionList/TransactionListAppliedFilter';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';

import { transactionApi, useTransactionGetTransactionList } from '../../../api/transaction';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { useWorkspace } from '../../../utils/helpers/common';
import { useGetTransactionData } from './TransactionData';
import { isEqual } from 'lodash';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import { getRuleName } from '../../../utils/helpers/helperFunctions';
interface Props {}

const TransactionList: React.FC<Props> = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    risk_level,
    currency,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    transaction_start_date,
    transaction_end_date,
    is_included_in_case,
    rule_name,
  } = getQueryParams();

  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.transactionFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currency: currency ? currency.split(',').map(Number) : [],
    created_start_date:
      created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    transaction_start_date:
      transaction_start_date && transaction_start_date !== 'null' ? transaction_start_date : null,
    transaction_end_date:
      transaction_end_date && transaction_end_date !== 'null' ? transaction_end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined
        ? null
        : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
  });
  const [isAllTransactionSelected, setIsAllTransactionSelected] = React.useState(false);
  const [selectedTransactions, setSelectedTransactions] = React.useState<number[]>([]);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [openAlertsModal, setAlertsModal] = React.useState(false);
  const selectedDropdownOptions = [
    {
      label: 'Update Open Alerts',
      value: 'alerts',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];

  const transactionQueryAll = useTransactionGetTransactionList({
    offset: 0,
    filters: {
      ...filters,
      rule_name: filters?.rule_name?.map(getRuleName),
    },
  });

  const [allTransactionsCount, allTransactionsData] = flattenInfiniteQueryResult(
    transactionQueryAll?.data
  );

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultTransactionListFilters;

    workspace.workspaces.forEach(w => {
      if (!localStorage.getItem(`compass.storage.transactionFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.transactionFilters.${w.slug}`,
          JSON.stringify(defaultTransactionListFilters)
        );
      }
    });

    if (isEqual(filters, defaultTransactionListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters);
    } else {
      setFilterStorage(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!transactionQueryAll.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultTransactionListFilters;
      setFilters(localStorageFilters);
      onChange(localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionQueryAll.isLoading]);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach(key => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters) => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      Object.keys(newFilters).forEach(key => {
        if (
          newFilters[key] !== null &&
          (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)
        ) {
          newSearchParams.set(key, newFilters[key]?.toString());
        }
      });
      setFilterStorage(newFilters);
      navigate('/transactions', Object.fromEntries(newSearchParams));
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach(key => {
      if (key === 'workflow_stage') val[key] = undefined;
      else if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultTransactionListFilters);
    syncFilters(defaultTransactionListFilters);
  };

  const selectTransaction = id => {
    if (selectedTransactions?.includes(id)) {
      setSelectedTransactions(selectedTransactions.filter(i => i !== id));
    } else {
      setSelectedTransactions([...selectedTransactions, id]);
    }
  };

  const selectAllTransactions = (force = false) => {
    if (force) {
      setSelectedTransactions(allTransactionsData.map(transaction => transaction.id));
    } else {
      if (selectedTransactions.length === allTransactionsData.length) {
        setSelectedTransactions([]);
        setIsAllTransactionSelected(false);
      } else {
        setSelectedTransactions(allTransactionsData.map(transaction => transaction.id));
      }
    }
  };

  const downloadSelectedTransactions = async () => {
    if (isAllTransactionSelected) {
      const res = await transactionApi.exportTransactionList({
        ids: isAllTransactionSelected ? [] : selectedTransactions,
        filters,
      });
      toast.success(res.data.message);
    } else {
      const res = await transactionApi.exportTransactionList({
        ids: selectedTransactions,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (newFilters?: IFilters) => {
    setSelectedTransactions([]);
    setIsAllTransactionSelected(false);
    if (newFilters) {
      syncFilters(newFilters);
    } else {
      syncFilters(filters);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'alerts') {
      setAlertsModal(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const transactionData = useGetTransactionData({
    selectAllTransactions,
    selectedTransactions,
    transactions: allTransactionsData,
    selectTransaction,
  });

  const headerData: (string | JSX.Element)[] = transactionData.headerData;
  const rows: ITableRow[] = transactionData.rowData;

  return (
    <>
      <Table
        title='Transactions'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${allTransactionsCount ?? 0} results`}
        filterComponent={
          <>
            {selectedTransactions.length > 0 && (
              <SimpleDropdown
                id='transactionList-menu'
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            <TransactionListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedTransactions.length > 0}
            />
          </>
        }
        appliedFilters={
          <TransactionListAppliedFilter
            count={allTransactionsCount}
            filters={filters}
            setFilters={handleApply}
            selectedTransactions={selectedTransactions}
            setSelectedTransactions={setSelectedTransactions}
            isAllTransactionSelected={isAllTransactionSelected}
            setIsAllTransactionSelected={setIsAllTransactionSelected}
            selectAllTransactions={selectAllTransactions}
            downloadSelectedTransactions={downloadSelectedTransactions}
            selectedText={
              isAllTransactionSelected
                ? `${allTransactionsCount} transactions selected.`
                : selectedTransactions.length === allTransactionsData.length
                  ? `All ${selectedTransactions.length} transactions in this page are selected.`
                  : `${selectedTransactions.length} ${
                      selectedTransactions.length > 1 ? 'transactions' : 'transaction'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(transactionQueryAll)}
      />
      <ResolveMultipleAlertsModal
        type='transaction'
        isOpen={openAlertsModal}
        isAllEntitiesSelected={isAllTransactionSelected}
        selectedEntities={selectedTransactions}
        refetchList={transactionQueryAll.refetch}
        onClose={() => {
          setAlertsModal(false);
        }}
        selectedTab={3}
        filters={filters}
        setSelectedEntities={setSelectedTransactions}
        setIsAllEntitiesSelected={setIsAllTransactionSelected}
      />
      <AddToCaseModal
        type='transaction'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedTransactions.map(transaction => {
          let data;
          allTransactionsData.forEach(txn => {
            if (txn.id === transaction) {
              data = {
                identifier: txn.identifier,
                currency: txn.currency,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllTransactionSelected}
        selectedTab={3}
        filters={filters}
      />
    </>
  );
};

export default TransactionList;
