import React from 'react';
import { CircleNotch, Trash } from '@phosphor-icons/react';
import { ICustomerAddressResponse, ICustomerAddressesResult } from '../../../api/dtos/customer';
import { RiskBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { Currencies } from '../../../utils/constants/constants';
import { formatDateShort } from '../../../utils/helpers/date';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import CustomerAddressArchiveModal from './CustomerAddressArchiveModal';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { useAuth } from '../../../modules/auth/AuthProvider';

interface ICustomerAddressSummaryProps {
  addressQuery: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  type: 'deposit' | 'withdrawal' | 'any';
}

const CustomerAddressSummary: React.FC<ICustomerAddressSummaryProps> = ({ addressQuery, type }) => {
  const { getParams } = useRouter();
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();
  const customerId = getParams().id || getParams().customerId;
  const encodedCustomerId = encodeURIComponent(customerId);
  const headerData = ['Address', 'Digital Assets', 'Risk Level', 'Balance', 'Date Added', ''];

  const getCurrencyShort = (currency: number) =>
    Currencies.find(c => c.value === currency)?.currency_short;

  const [archiveAddress, setArchiveAddress] = React.useState<ICustomerAddressesResult>(null);

  const screenApi = useScreenApi();
  const [, addresses] = flattenInfiniteQueryResult(addressQuery?.data);

  const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);

  const rows = addresses?.map(a => {
    return {
      id: a.identifier,
      currency: a.currency,
      data: [
        <IdentifierEllipsis
          key={a.id}
          type='addresses'
          identifier={a.identifier}
          currency={a.currency}
          customerId={customerId}
          customerType={a.type}
          clickable
          copyable
        />,
        <DigitalAssets
          key={a.id}
          digitalAssets={a.digital_assets?.length ? a.digital_assets : [{ currency: a.currency }]}
        />,
        <RiskBadge key={a.id} risk={a.risk_level} />,
        <CurrencyValue key={a.id} value={a.balance} currency={getCurrencyShort(a.currency)} />,
        formatDateShort(a.created_at, timezone),
        isArchiveLoading && archiveAddress?.id === a.id ? (
          <CircleNotch key={a.id} size={16} className='animate-spin' />
        ) : (
          <Trash
            key={a.id}
            size={16}
            id='archive'
            data-testid='archive'
            className='cursor-pointer hover:text-red-400'
            onClick={e => {
              e.stopPropagation();
              setArchiveAddress(a);
            }}
          />
        ),
      ],
    };
  });

  const onClickRow = (id: string, currency: number) => {
    screenApi({
      identifier: id,
      currency: Number(currency),
      customer_id: customerId,
      type: type === 'any' ? 0 : type === 'deposit' ? 1 : 2,
      entityType: 'addresses',
    });
  };

  return (
    <>
      <CustomerAddressArchiveModal
        id={archiveAddress?.id}
        type={type}
        archiveAddress={archiveAddress}
        setArchiveAddress={setArchiveAddress}
        identifier={archiveAddress?.identifier}
        customerId={encodedCustomerId}
        setIsArchiveLoading={setIsArchiveLoading}
      />
      <Table
        onClickRow={onClickRow}
        isHeaderVisible={false}
        headerData={headerData}
        rows={rows}
        height={300}
        className='rounded-md'
        {...buildInfiniteQueryTableProps(addressQuery)}
        isLoading={addressQuery.isRefetching}
      />
    </>
  );
};

export default CustomerAddressSummary;
