import { ICustomerTokensResult } from '../../../api/dtos/customer';
import Table from '../../../ui/components/Table/Table';
import { formatDateShort } from '../../../utils/helpers/date';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import { useAuth } from '../../../modules/auth/AuthProvider';
interface IDigitalAssetSummaryProps {
  assets: ICustomerTokensResult[];
  isLoading: boolean;
  valueType?: 'digital' | 'usd';
}

const DigitalAssetSummary: React.FC<IDigitalAssetSummaryProps> = ({
  assets,
  isLoading,
  valueType,
}) => {
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();
  const headerData = [
    'Token',
    'Balance',
    'Received Amt.',
    'Sent Amt.',
    'First Txn.',
    'Latest Txn.',
  ];

  const rows = assets?.map(asset => {
    return {
      id: asset.currency_short,
      data: [
        <CurrencyBadge
          key={asset.currency_short}
          currency={asset.currency}
          label={asset.currency_short}
        />,
        <CurrencyValue
          key={asset.currency_short}
          value={asset.balance}
          currency={asset.currency_short}
        />,
        <CurrencyValue
          key={asset.currency_short}
          value={
            valueType === 'digital' ? asset.total_incoming_value : asset.total_incoming_value_usd
          }
          currency={valueType === 'digital' ? asset.currency_short : 'USD'}
        />,
        <CurrencyValue
          key={asset.currency_short}
          value={
            valueType === 'digital' ? asset.total_outgoing_value : asset.total_outgoing_value_usd
          }
          currency={valueType === 'digital' ? asset.currency_short : 'USD'}
        />,
        formatDateShort(asset.earliest_transaction_time, timezone),
        formatDateShort(asset.latest_transaction_time, timezone),
      ],
    };
  });

  return (
    <Table
      isHeaderVisible={false}
      headerData={headerData}
      rows={rows}
      isLoading={isLoading}
      height={400}
      className='rounded-md'
    />
  );
};

export default DigitalAssetSummary;
