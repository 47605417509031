import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from './api';
import {
  IAlert,
  IAlertGetAlertCyclesRequest,
  IAlertGetAlertItemsRequest,
  IAlertGetAlertItemsResponse,
  IAlertGetAlertRequest,
  IAlertGetAlertsCyclesResponse,
  IAlertGetAlertsRequest,
  IAlertGetAlertsResponse,
  IAlertGetAllAlertsRequest,
  IAlertGetAllAlertsResponse,
  IAlertUpdateAlertItemRequest,
  IAlertUpdateAlertMonitoringRequest,
  IAlertUpdateAlertRequest,
  IAlertUpdateAlertsRequest,
  IUpdateAlerts,
  IUpdateAlertsResponse,
} from './dtos/alert';
import { IStatusResponse } from './dtos/common';
import { getApiBaseUrl, getTypeApi } from '../utils/helpers/apiHelpers';
import { getNextPageParam, getRuleName } from '../utils/helpers/helperFunctions';
import { useCachedInfiniteQuery } from '../utils/helpers/react-query.helper';
export class AlertApi extends Api {
  readonly baseUrl: string = '/resolutioncenter/api/v4';

  readonly getAlerts = (params: IAlertGetAlertsRequest) => {
    const {
      offset = 0,
      type,
      status,
      id,
      filters,
      is_stale,
      entity_type,
      assigned_to,
      closed = false,
      assigned = false,
    } = params;
    if (assigned) {
      return this.http.get<IAlertGetAlertsResponse>(
        this.route(`${getTypeApi(type)}/${id}/alerts/`),
        {
          params: {
            offset,
            entity_type,
            is_stale,
            status,
            ...filters,
            assigned_to,
            assigned_to_name: undefined,
          },
        }
      );
    }
    if (closed) {
      return this.http.get<IAlertGetAlertsResponse>(
        this.route(`${getTypeApi(type)}/${id}/alerts/`),
        {
          params: {
            offset,
            entity_type,
            is_stale,
            assigned_to,
            ...filters,
            status,
            assigned_to_name: undefined,
          },
        }
      );
    }
    return this.http.get<IAlertGetAlertsResponse>(this.route(`${getTypeApi(type)}/${id}/alerts/`), {
      params: {
        offset,
        status,
        entity_type,
        is_stale,
        assigned_to,
        ...filters,
        assigned_to_name: undefined,
      },
    });
  };

  readonly getAlert = (params: IAlertGetAlertRequest) => {
    const { type, id, alertId } = params;
    return this.http.get<IAlert>(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/`));
  };

  readonly getAlertItems = (params: IAlertGetAlertItemsRequest) => {
    const { offset = 0, status, type, id, alertId, start_time, end_time } = params;

    return this.http.get<IAlertGetAlertItemsResponse>(
      this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/items/`),
      {
        params: { offset, status, start_time, end_time },
      }
    );
  };

  readonly updateAlert = (params: IAlertUpdateAlertRequest) => {
    const { type, id, status, assigned_to, alertId, comment, context } = params;

    return this.http.put(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/`), {
      workflow_stage: status,
      assigned_to,
      ...(comment && { comment: { body: comment, context } }),
    });
  };

  readonly updateMultipleAlerts = (params: IUpdateAlerts) => {
    const { ids, filters, comment, is_stale, q, updated_status, updated_assigned_to } = params;

    return this.http.put<IUpdateAlertsResponse>(
      this.route(`/alerts/update-alerts/?ids=${ids.join(',')}`),
      {
        workflow_stage: updated_status,
        assigned_to: updated_assigned_to,
        ...(comment && {
          comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } },
        }),
      },
      {
        params: {
          ...filters,
          is_stale,
          q,
        },
      }
    );
  };

  readonly updateAlertItem = (params: IAlertUpdateAlertItemRequest) => {
    const { type, id, status, alertId, itemId, comment, context } = params;

    return this.http.put(
      this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/items/${itemId}/`),
      {
        status,
        ...(comment && { comment: { body: comment, context } }),
      }
    );
  };

  readonly updateAlertMonitoring = (params: IAlertUpdateAlertMonitoringRequest) => {
    const { type, id, days = null, isMuted = true, alertId } = params;

    return this.http.put(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/monitoring/`), {
      days,
      is_muted: isMuted,
    });
  };

  readonly getAlertCycles = (params: IAlertGetAlertCyclesRequest) => {
    const { type, id, alertId } = params;

    return this.http.get<IAlertGetAlertsCyclesResponse>(
      this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/cycles/`)
    );
  };

  readonly updateAlerts = (params: IAlertUpdateAlertsRequest) => {
    const { type, ids, status, comment, context } = params;

    return this.http.put<IStatusResponse>(
      `${getApiBaseUrl(type)}/update-alerts/`,
      {
        status,
        ...(comment && { comment: { body: comment, context } }),
      },
      {
        params: { ids },
      }
    );
  };

  readonly getAllAlerts = (params: IAlertGetAllAlertsRequest) => {
    if (params?.closed) {
      return this.http.get<IAlertGetAllAlertsResponse>(this.route('alerts/'), {
        params: {
          ...params,
          status: params.status,
          closed: undefined,
          assigned_to_name: undefined,
        },
      });
    }
    return this.http.get<IAlertGetAllAlertsResponse>(this.route('alerts/'), {
      params: { ...params, assigned_to_name: undefined },
    });
  };
}

export const alertApi = new AlertApi();

export const useAlertGetAlerts = (params: IAlertGetAlertsRequest, options?: UseQueryOptions) => {
  const key = [
    'alertApi.getAlerts',
    params.id,
    params.type,
    params.filters,
    params.entity_type,
    params.assigned_to,
    params.assigned,
    params.status,
    params.closed,
  ];
  const result = useCachedInfiniteQuery(
    key,
    ({ pageParam = 0 }) =>
      alertApi.getAlerts({
        ...params,
        offset: pageParam,
        filters: {
          ...params.filters,
          rule_name: params?.filters?.rule_name?.map(getRuleName),
        },
      }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAlert = (params: IAlertGetAlertRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAlert', params.id, params.alertId, params.type];
  const result = useQuery(key, () => alertApi.getAlert(params), options as unknown);
  return result;
};

export const useAlertGetAlertItems = (
  params: IAlertGetAlertItemsRequest,
  options?: UseQueryOptions
) => {
  const key = ['alertApi.getAlertItems', params.id, params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAlertItems({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAlertCycles = (
  params: IAlertGetAlertCyclesRequest,
  options?: UseQueryOptions
) => {
  const key = ['alertApi.getAlertsHistory', params.id, params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAlertCycles({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAllAlerts = (
  params: IAlertGetAllAlertsRequest,
  options?: UseQueryOptions
) => {
  const key = ['alertApi.getAllAlerts', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) =>
      alertApi.getAllAlerts({ ...params, rule_name: undefined, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
