import * as React from 'react';
import { useLocalStorage } from 'react-use';
import { CheckCircle, DotsThreeOutlineVertical, FolderPlus } from '@phosphor-icons/react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import AddressListFilter, {
  IFilters,
  defaultAddressListFilters,
} from '../../../components/Address/AddressList/AddressListFilter';
import AddressListAppliedFilter from '../../../components/Address/AddressList/AddressListAppliedFilter';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';

import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import {
  addressApi,
  useAddressGetAddressList,
  useAddressGetAddressNameVerboses,
} from '../../../api/address';

import { useWorkspace } from '../../../utils/helpers/common';
import { useGetAddressData } from '../addresses/AddressData';
import { isEqual } from 'lodash';
import { useCustomTagsGetCustomTags } from '../../../api/settings/customTags';
import TokenAccountModal, {
  TokenModalType,
} from '../../../components/Address/AddressList/TokenAccountModal';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import { getRuleName } from '../../../utils/helpers/helperFunctions';

interface Props {}

const AddressList: React.FC<Props> = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    tag_type_verboses,
    tag_name_verboses,
    risk_level,
    currency,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    is_included_in_case,
    rule_name,
    custom_tags,
  } = getQueryParams();
  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.addressFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    tag_type_verboses: tag_type_verboses ? tag_type_verboses.split(',') : [],
    tag_name_verboses: tag_name_verboses ? tag_name_verboses.split(',') : [],
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currency: currency ? currency.split(',').map(Number) : [],
    created_start_date:
      created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined
        ? null
        : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
    custom_tags: custom_tags ? custom_tags.split(',') : [],
  });
  const selectedDropdownOptions = [
    {
      label: 'Update Open Alerts',
      value: 'alerts',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];
  const [entityNameVerboseOptions, setEntityNameVerboseOptions] = React.useState([]);
  const [isAllAddressSelected, setIsAllAddressSelected] = React.useState(false);
  const [selectedAddresses, setSelectedAddresses] = React.useState<number[]>([]);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [tokenModal, setTokenModal] = React.useState<TokenModalType>(null);
  const [openAlertsModal, setAlertsModal] = React.useState(false);

  useAddressGetAddressNameVerboses({
    onSuccess: ({ data }) => {
      setEntityNameVerboseOptions(
        data.results.map(option => ({
          label: option,
          value: option,
        }))
      );
    },
  });

  const customTagsListQuery = useCustomTagsGetCustomTags({ is_in_custom_list: false });
  const [, customTagsData] = flattenInfiniteQueryResult(customTagsListQuery.data);
  const customTagsOptions = customTagsData.map(tag => {
    return {
      label: tag.name,
      value: tag.id.toString(),
    };
  });

  const addressQueryAll = useAddressGetAddressList({
    offset: 0,
    filters: {
      ...filters,
      rule_name: filters?.rule_name?.map(getRuleName),
    },
  });

  const [allAddressesCount, allAddressesData] = flattenInfiniteQueryResult(addressQueryAll?.data);

  React.useEffect(() => {
    if (!addressQueryAll.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAddressListFilters;
      setFilters(localStorageFilters);
      onChange(localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressQueryAll.isLoading]);

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultAddressListFilters;

    workspace.workspaces.forEach(w => {
      if (!localStorage.getItem(`compass.storage.addressFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.addressFilters.${w.slug}`,
          JSON.stringify(defaultAddressListFilters)
        );
      }
    });

    if (isEqual(filters, defaultAddressListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters);
    } else {
      setFilterStorage(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach(key => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters) => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      Object.keys(newFilters).forEach(key => {
        if (
          newFilters[key] !== null &&
          (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)
        ) {
          newSearchParams.set(key, newFilters[key]?.toString());
        }
      });
      setFilterStorage(newFilters);
      navigate('/addresses', Object.fromEntries(newSearchParams));
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach(key => {
      if (key === 'workflow_stage') val[key] = undefined;
      else if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultAddressListFilters);
    syncFilters(defaultAddressListFilters);
  };

  const selectAddress = id => {
    if (selectedAddresses?.includes(id)) {
      setSelectedAddresses(selectedAddresses.filter(i => i !== id));
    } else {
      setSelectedAddresses([...selectedAddresses, id]);
    }
  };

  const selectAllAddresses = (force = false) => {
    if (force) {
      setSelectedAddresses(allAddressesData.map(address => address.id));
    } else {
      if (selectedAddresses.length === allAddressesData.length) {
        setSelectedAddresses([]);
        setIsAllAddressSelected(false);
      } else {
        setSelectedAddresses(allAddressesData.map(address => address.id));
      }
    }
  };

  const downloadSelectedAddresses = async () => {
    if (isAllAddressSelected) {
      const res = await addressApi.exportAddressList({
        ids: isAllAddressSelected ? [] : selectedAddresses,
        filters,
      });
      toast.success(res.data.message);
    } else {
      const res = await addressApi.exportAddressList({
        ids: selectedAddresses,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (newFilters?: IFilters) => {
    setSelectedAddresses([]);
    setIsAllAddressSelected(false);
    if (newFilters) {
      syncFilters(newFilters);
    } else {
      syncFilters(filters);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'alerts') {
      setAlertsModal(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const addressData = useGetAddressData({
    selectAllAddresses,
    addresses: allAddressesData,
    selectedAddresses,
    selectAddress,
    setTokenModal,
  });

  const headerData: (string | JSX.Element)[] = addressData.headerData;
  const rows: ITableRow[] = addressData.rowData;

  return (
    <>
      <Table
        title='Addresses'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${allAddressesCount ?? 0} results`}
        filterComponent={
          <>
            {selectedAddresses.length > 0 && (
              <SimpleDropdown
                id='addressList-menu'
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            <AddressListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedAddresses.length > 0}
              entityNameVerboseOptions={entityNameVerboseOptions}
              customTagsOptions={customTagsOptions}
            />
          </>
        }
        appliedFilters={
          <AddressListAppliedFilter
            count={allAddressesCount}
            filters={filters}
            setFilters={handleApply}
            selectedAddresses={selectedAddresses}
            setSelectedAddresses={setSelectedAddresses}
            isAllAddressSelected={isAllAddressSelected}
            setIsAllAddressSelected={setIsAllAddressSelected}
            selectAllAddresses={selectAllAddresses}
            downloadSelectedAddresses={downloadSelectedAddresses}
            customTagsOptions={customTagsOptions}
            selectedText={
              isAllAddressSelected
                ? `${allAddressesCount} addresses selected.`
                : selectedAddresses.length === allAddressesData.length
                  ? `All ${selectedAddresses.length} addresses in this page are selected.`
                  : `${selectedAddresses.length} ${
                      selectedAddresses.length > 1 ? 'addresses' : 'address'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(addressQueryAll)}
      />
      <TokenAccountModal tokenModal={tokenModal} setTokenModal={setTokenModal} />
      <ResolveMultipleAlertsModal
        type='address'
        isOpen={openAlertsModal}
        isAllEntitiesSelected={isAllAddressSelected}
        selectedEntities={selectedAddresses}
        refetchList={addressQueryAll.refetch}
        onClose={() => {
          setAlertsModal(false);
        }}
        selectedTab={3}
        filters={filters}
        setSelectedEntities={setSelectedAddresses}
        setIsAllEntitiesSelected={setIsAllAddressSelected}
      />
      <AddToCaseModal
        type='address'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedAddresses.map(address => {
          let data;
          allAddressesData.forEach(add => {
            if (add.id === address) {
              data = {
                identifier: add.identifier,
                currency: add.currency,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllAddressSelected}
        selectedTab={3}
        filters={filters}
      />
    </>
  );
};

export default AddressList;
