import React, { FC, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { transactionApi } from '../../../../api/transaction';
import {
  flattenInfiniteQueryResult,
  getNextPageParamByOffset,
} from '../../../../utils/helpers/react-query.helper';
import { ITransactionResponse } from '../../../../api/dtos/transaction';
import Pagination from '../../../../containers/Pagination';
import TransactionEntity from './TransactionEntity';
import { ArrowRight } from '@phosphor-icons/react';
import ExternalTransaction from './ExternalTransaction';
import Tab, { ITabValue } from '../../../ui/components/Tab/Tab';
import classNames from 'classnames';
import { isAddressScreeningDisabled } from '../../../../utils/helpers/currency';
import CounterpartyFilter, {
  IFilters,
  defaultStateFilters,
} from '../../../Address/Overview/CounterpartyFilter';
import CounterpartyAppliedFilters from '../../../Address/Overview/CounterpartyFilter/CounterpartyAppliedFilters';
import { Card } from '../../../ui/components/Card';
import Skeleton from 'react-loading-skeleton';
import { transactionLiteApi } from '../../../../api/transactionLite';

interface TokenTransactionProps {
  transaction: ITransactionResponse;
  setCustomerAddresses?: (addresses: string[]) => void;
  defaultEntities?: string[];
  defaultEntitySubTypes?: string[];
  defaultEntityName?: string;
  popover?: boolean;
}

const TokenTransaction: FC<TokenTransactionProps> = props => {
  const {
    transaction,
    setCustomerAddresses,
    defaultEntities,
    defaultEntityName,
    defaultEntitySubTypes,
    popover,
  } = props;
  const isAlertDetail = !!(defaultEntities || defaultEntitySubTypes || defaultEntityName);
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);

  useEffect(() => {
    if (defaultEntities) {
      setFilters(prev => ({ ...prev, entities: defaultEntities }));
    }
    if (defaultEntitySubTypes) {
      setFilters(prev => ({ ...prev, entitySubtypes: defaultEntitySubTypes }));
    }
    if (defaultEntityName) {
      setFilters(prev => ({ ...prev, entityName: defaultEntityName }));
    }
  }, [defaultEntities, defaultEntityName, defaultEntitySubTypes]);
  // const externalTransactionQuery = useQuery(['transactionApi.getExternalTransaction', transaction.id], () =>
  //   transactionApi.getExternalTransaction({ id: transaction.id })
  // );
  const mappedFilters = useMemo(() => {
    return {
      tag_type_verboses: filters.entities,
      tag_subtype_verboses: filters.entitySubtypes,
      tag_name_verbose: filters.entityName,
    };
  }, [filters]);
  const inputsQueryFull = useInfiniteQuery(
    ['transactionApi.getInputs', transaction.id, filters],
    ({ pageParam = 0 }) =>
      transactionApi.getInputs({ id: transaction.id, offset: pageParam, filters: mappedFilters }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const outputsQueryFull = useInfiniteQuery(
    ['transactionApi.getOutputs', transaction.id, filters],
    ({ pageParam }) =>
      transactionApi.getOutputs({ id: transaction.id, offset: pageParam, filters: mappedFilters }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const inputsQueryLite = useInfiniteQuery(
    ['transactionLiteApi.getInputsLite', transaction.identifier, filters, transaction.currency],
    ({ pageParam = 0 }) =>
      transactionLiteApi.getInputsLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );

  const outputsQueryLite = useInfiniteQuery(
    ['transactionLiteApi.getOutputsLite', transaction.identifier, filters, transaction.currency],
    ({ pageParam }) =>
      transactionLiteApi.getOutputsLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );
  const inputsQuery = !popover ? inputsQueryFull : inputsQueryLite;
  const outputsQuery = !popover ? outputsQueryFull : outputsQueryLite;
  const inputData = useMemo(
    () => flattenInfiniteQueryResult(inputsQuery?.data)[1],
    [inputsQuery?.data]
  );

  const outputData = useMemo(
    () => flattenInfiniteQueryResult(outputsQuery?.data)[1],
    [outputsQuery?.data]
  );

  const internalTransactionQueryFull = useInfiniteQuery(
    ['transactionApi.getInternalTransactions', transaction.id, filters],
    ({ pageParam = 0 }) =>
      transactionApi.getInternalTransactions({
        id: transaction.id,
        offset: pageParam,
        filters: mappedFilters,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const tokenTransfersQueryFull = useInfiniteQuery(
    ['transactionApi.getTokenTransfers', transaction.id, filters],
    ({ pageParam = 0 }) =>
      transactionApi.getTokenTransfers({
        id: transaction.id,
        offset: pageParam,
        filters: mappedFilters,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const internalTransactionQueryLite = useInfiniteQuery(
    [
      'transactionLiteApi.getInternalTransactionsLite',
      transaction.identifier,
      transaction.currency,
      filters,
    ],
    ({ pageParam = 0 }) =>
      transactionLiteApi.getInternalTransactionsLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );

  const tokenTransfersQueryLite = useInfiniteQuery(
    [
      'transactionLiteApi.getTokenTransfersLite',
      transaction.identifier,
      transaction.currency,
      filters,
    ],
    ({ pageParam = 0 }) =>
      transactionLiteApi.getTokenTransfersLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );
  const internalTransactionQuery = !popover
    ? internalTransactionQueryFull
    : internalTransactionQueryLite;
  const tokenTransfersQuery = !popover ? tokenTransfersQueryFull : tokenTransfersQueryLite;
  const ethInternalTransactions = useMemo(
    () => flattenInfiniteQueryResult(internalTransactionQuery?.data)[1],
    [internalTransactionQuery?.data]
  );

  const TokenTransactionsResult = useMemo(
    () => flattenInfiniteQueryResult(tokenTransfersQuery.data)[1],
    [tokenTransfersQuery.data]
  );

  // const validEthInternalResults = useCallback(() => {
  //   if (!internalTransactionQuery.data) return [];

  //   const [, ethInternalTransactions] = flattenInfiniteQueryResult(internalTransactionQuery.data);

  //   if (!ethInternalTransactions || !ethExternalTransactions) return [];

  //   return ethInternalTransactions.filter((trans) => {
  //     return !ethExternalArr.find(
  //       (e) =>
  //         e.input.address === trans.input.address &&
  //         e.output.address === trans.output.address &&
  //         e.output.value === trans.output.value
  //     );
  //   });
  // }, [externalTransactionQuery.data?.data, internalTransactionQuery.data]);

  useEffect(() => {
    const totalAddresses = () => {
      const output = [];
      if (outputData?.length) {
        output.push(...outputData.map(result => result.address));
      }
      if (ethInternalTransactions?.length) {
        output.push(...ethInternalTransactions.map(r => r.output.address));
      }
      if (tokenTransfersQuery.data) {
        output.push(
          ...tokenTransfersQuery.data.pages.flatMap(page =>
            page.data.results.map(token => token.output.address)
          )
        );
      }
      const outputList = Array.from(new Set(output)).filter(
        address => !isAddressScreeningDisabled(address, transaction.currency)
      );
      return outputList;
    };
    setCustomerAddresses?.(totalAddresses());
  }, [
    ethInternalTransactions,
    outputData,
    setCustomerAddresses,
    tokenTransfersQuery.data,
    transaction.currency,
  ]);
  const isTransactionTabHidden = !(inputData?.length && outputData?.length);
  const isInternalTabHidden = !ethInternalTransactions.length;
  const isTokenTransferTabHidden = !tokenTransfersQuery.data?.pages[0].data.count;

  const tabs: ITabValue[] = [
    { label: 'Transaction', hidden: isTransactionTabHidden && !isAlertDetail },
    { label: 'Internal Transactions', hidden: isInternalTabHidden && !isAlertDetail },
    { label: 'Token Transfers', hidden: isTokenTransferTabHidden && !isAlertDetail },
  ];

  if (
    inputsQuery.isLoading ||
    outputsQuery.isLoading ||
    internalTransactionQuery.isLoading ||
    tokenTransfersQuery.isLoading
  ) {
    return (
      <Card title={'Transaction'} containerClassName='border'>
        <Skeleton count={3} />
      </Card>
    );
  }

  if (isTransactionTabHidden && isInternalTabHidden && isTokenTransferTabHidden) {
    return null;
  }

  return (
    <div>
      <div className='rounded-md border'>
        <Tab
          headerActions={
            !!(defaultEntitySubTypes || defaultEntityName || defaultEntities) && (
              <CounterpartyFilter filters={filters} onApply={setFilters} />
            )
          }
          values={tabs}
          type='primary'
          listClassName='border-b py-2 px-4 rounded-md'
          panelClassName='p-4'
          subHeader={
            <CounterpartyAppliedFilters
              filters={filters}
              setFilters={setFilters}
              className='-mx-4 mt-1 border-t'
            />
          }
          isLoading={
            inputsQuery.isLoading || outputsQuery.isLoading || internalTransactionQuery.isLoading
          }
        >
          <ExternalTransaction
            transaction={transaction}
            inputsQuery={inputsQuery}
            outputsQuery={outputsQuery}
            isHidden={isTransactionTabHidden}
          />
          <Pagination
            query={internalTransactionQuery}
            queryData={ethInternalTransactions}
            className={classNames(
              { grid: !internalTransactionQuery.isLoading && ethInternalTransactions.length },
              'max-h-[400px] grid-cols-12 overflow-auto'
            )}
          >
            {entity => (
              <React.Fragment key={entity.input.address + entity.output.address}>
                <TransactionEntity
                  entity={entity.input}
                  transaction={transaction}
                  className='col-span-5 pb-4 text-sm'
                />
                <div className='col-span-2 mx-auto'>
                  <ArrowRight size={32} weight='bold' color='#6B7280' />
                </div>
                <TransactionEntity
                  entity={entity.output}
                  transaction={transaction}
                  className='col-span-5 text-sm'
                />
              </React.Fragment>
            )}
          </Pagination>
          <Pagination
            query={tokenTransfersQuery}
            className={classNames(
              { grid: !tokenTransfersQuery.isLoading && TokenTransactionsResult.length },
              'max-h-[400px] grid-cols-12 overflow-auto'
            )}
          >
            {entity => (
              <React.Fragment key={entity.input.address + entity.output.address}>
                <TransactionEntity
                  entity={entity.input}
                  transaction={transaction}
                  className='col-span-5 pb-4 text-sm'
                />
                <div className='col-span-2 mx-auto'>
                  <ArrowRight size={32} weight='bold' color='#6B7280' />
                </div>
                <TransactionEntity
                  entity={entity.output}
                  transaction={transaction}
                  className='col-span-5 text-sm'
                />
              </React.Fragment>
            )}
          </Pagination>
        </Tab>
      </div>
    </div>
  );
};

export default TokenTransaction;
